.dialog-backdrop {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-items: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);

  #offline-dialog {
    width: 492px;
    border: none;
    border-radius: 10px;
    padding: 69px 84px 49px;

    .offline-modal-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      p {
        margin: 0;
      }
      p.title {
        font-family: 'Prompt-Bold', sans-serif;
        font-size: 21px;
        color: #1f2d39;
        margin-bottom: 16px;
      }
      p.message {
        font-family: 'Prompt', sans-serif;
        font-size: 16px;
        color: #1f2d39;
        line-height: 1.63;
        margin-bottom: 52px;
      }
      button {
        width: 140px;
      }
    }
  }
}
